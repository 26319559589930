import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import Animation from "../Animations/Animation";

const Footer = () => {
  return (
    <div className={styles.footer_section}>
      <div className={styles.footer_animation}> 
      <Animation margin={'215px'}/>
      </div>
      <div className={styles.footer_subsection}>
        <h2>
          Ready to give your customers the shopping experience they deserve?
        </h2>
        <Link to="/bookdemo">
          <button>Book Demo</button>
        </Link>
      </div>
      <div className={styles.credits_footer}>
        <div className={styles.credits}>
        <a href="/cancellation-and-refund-policy" target="_blank" rel="noreferrer">
          Cancellation & Refund Policy
        </a>
        <a href="/terms-and-conditions" target="_blank" rel="noreferrer">
          Terms & Conditions
        </a>
        <a href="/" target="_blank" rel="noreferrer">
        &copy;Troopod.in
        </a>
        <a href="/privacy-policy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        <a href="mailto:foundersoffice@pushstart.in" target="_blank" rel="noreferrer">
        foundersoffice@pushstart.in
        </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
