import React from "react";
import styles from './style.module.css'


const CancellationRefundPolicy = () => {
  return (
    <div className={styles.cancellation}>
      <h2 style={{textAlign:"center",padding:"20px 0px"}}>Cancellation & Refund Policy</h2>
      <p>
        At Troopod, we strive to provide exceptional service and a seamless user experience. To ensure
        clarity, the following outlines our refund and cancellation policy for all SaaS subscriptions:
      </p>
      <h3>1. Cancellation Policy</h3>
      <h4>Pre-Go-Live Cancellation:</h4>
      <ul>
        <li>Subscriptions cannot be cancelled at any time without a 30-day prior notice.</li>
      </ul>
      <h4>Post-Go-Live Cancellation:</h4>
      <ul>
        <li>Cancellations after the service has gone live are not eligible for a refund.</li>
        <li>
          For ongoing subscriptions, a 30-day prior notice is required to process the cancellation for the
          next billing cycle.
        </li>
      </ul>
      <h3>2. Refund Policy</h3>
      <ul>
        <li>
          <strong>Pre-Go-Live Refunds:</strong> Full refunds are available if the service is cancelled prior
          to going live.
        </li>
        <li>
          <strong>Post-Go-Live Refunds:</strong> No refunds will be issued once the service has gone live.
        </li>
      </ul>
      <h3>3. How to Request a Cancellation</h3>
      <ul>
        <li>
          To request a cancellation, please contact us at{" "}
          <a href="mailto:foundersoffice@pushstart.in">foundersoffice@pushstart.in</a> with your account
          details.
        </li>
        <li>
          The services will continue until the end of the current billing period unless terminated by Troopod
          or the Client.
        </li>
      </ul>
      <h3>4. Termination by Troopod</h3>
      <p>
        Troopod reserves the right to terminate services under specific circumstances, such as non-compliance
        with terms or misuse of the platform, with appropriate notice.
      </p>
      <p>
        This policy ensures transparency and fairness for both parties, allowing users to fully understand
        their rights and responsibilities when using Troopod’s services.
      </p>
    </div>
  );
};

export default CancellationRefundPolicy;
