import React from 'react'



const Privacy = () =>{
    return(
        <div style={{ padding: '30px'}}>
            <p style={{textAlign:'center',fontSize:'25px',fontWeight:'bold'}}>PRIVACY POLICY</p>
            <p><br /><br /><br /></p>
            <p>Troopod.io ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, share, and safeguard your information when you visit our website Troopod.io. By using our website, you agree to the terms of this Privacy Policy.</p>
            <p><strong>Information We Collect</strong></p>
            <p>We collect both personal and non-personal information to enhance your experience on our website.</p>
            <ol>
                <li>Personal Information:&nbsp;</li>
            </ol>
            <div style={{paddingLeft:'30px'}}>
            <p>We may collect personal information when you:</p>
            <p>- Go live with our modules on your website&nbsp;</p>
            <p>- Contact us or request information</p>
            <p>- Subscribe to newsletters or updates</p>
            <p>Examples include:</p>
            <p>- Name</p>
            <p>- Email address</p>
            <p>- Phone number</p>
            <p>- Company name</p>
            <p>- Company GST number</p>
            </div>
            <ol>
                <li>Non-Personal Information:</li>
            </ol>
            <div style={{paddingLeft:'30px'}}>
            <p>We may also collect non-personal information, such as:</p>
            <p>- IP address</p>
            <p>- Browser type</p>
            <p>- Device information</p>
            <p>- Usage data (e.g., pages visited, time spent on site)</p>
            <p><strong>How We Use Your Information</strong></p>
            <p>We use the information collected to:</p>
            <p>- Provide and manage our services</p>
            <p>- Respond to inquiries and provide customer support</p>
            <p>- Improve website functionality and user experience</p>
            <p>- Analyze website usage for internal analytics</p>
            <p><strong>Sharing Your Information</strong></p>
            <p>We respect your privacy and will never sell your information. However, we may share your information with:</p>
            <p>- Service Providers: Trusted third parties who assist in operating our website and services.</p>
            <p>- Legal Authorities: When required by law or to protect our legal rights.</p>
            <p>- Business Transfers: In case of a merger, acquisition, or asset sale, your information may be transferred.</p>
            <p><strong>Cookies and Tracking Technologies</strong><br />Troopod.io uses cookies to enhance your browsing experience. Cookies may track your preferences and activity. You can manage your cookie settings through your browser.</p>
            <p><strong>Data Security</strong></p>
            <p>We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet is 100% secure.</p>
            <p><strong>Your Rights</strong></p>
            <p>Depending on your location, you may have rights under applicable privacy laws, including:</p>
            <p>- Accessing and correcting your personal information</p>
            <p>- Requesting deletion of your data</p>
            <p>- Opting out of promotional communications</p>
            <p>To exercise these rights, contact us at foundersoffice@pushstart.in</p>
            <p><strong>Third-Party Links</strong></p>
            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites and encourage you to read their privacy policies.</p>
            <p><strong>Changes to This Policy</strong></p>
            <p>We may update this Privacy Policy periodically. Any changes will be posted on this page with the updated effective date. Your continued use of the website signifies your acceptance of the changes.</p>
            <p><strong>Contact Us&nbsp;</strong></p>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
            <p>Email: <a href="mailto:neeraj.joshi@pushstart.in">neeraj.joshi@pushstart.in</a> | <a href="mailto:foundersoffice@pushstart.in">foundersoffice@pushstart.in</a><br />Contact Number: +91 877 921 9493</p>
            </div>
        </div>
    )
}

export default Privacy